import { concat, every, some } from 'lodash';
import { store } from '../store';

function hasPermission(permissionsToCheck: string[], matchAll = false) {
  if (!permissionsToCheck.length) return true;

  const { auth } = store.getState();
  const { permissions: userPersmissions } = auth;

  const operation = matchAll ? every : some;
  return operation(permissionsToCheck, (permission) => {
    const [app, action] = permission.split('.');

    const allowed = concat(userPersmissions?.allow?.[app] ?? [], userPersmissions?.allow?.['*']);
    const denied = concat(userPersmissions?.deny?.[app] ?? [], userPersmissions?.deny?.['*']);

    return (
      (allowed.includes('*') || allowed.includes(action)) &&
      !(denied.includes('*') || denied.includes(action))
    );
  });
}

export default hasPermission;
