import { ApolloError } from '@apollo/client';
import { FormInstance, notification } from 'antd';
import { toPath } from 'lodash';
import { logger } from 'utils';

function getErrorMessage(apolloErr: ApolloError) {
  const error = apolloErr.graphQLErrors[0];
  if (!error) return { message: apolloErr.name, description: apolloErr.message };

  const { originalError, code }: { originalError?: PlainObject; code?: string } = error.extensions;

  if (code === 'GRAPHQL_VALIDATION_FAILED') {
    return {
      message: 'GraphQL Schema Validation Failed',
      description: originalError?.message,
    };
  }

  return {
    message: `Request Failed${
      originalError?.statusCode && originalError?.statusCode !== 200
        ? ` (${originalError?.statusCode})`
        : ''
    }`,
    description: originalError?.message ?? error.message,
  };
}

function convertToIndex(path: string) {
  const index = Number(path);
  return Number.isNaN(index) ? path : index;
}

function getFormErrorsFromError(apolloErr: ApolloError) {
  const error = apolloErr.graphQLErrors[0];
  if (!error) return [];

  const { originalError }: { originalError?: PlainObject } = error.extensions ?? {};

  if (!originalError?.errors) {
    logger.error({ err: error }, 'NON_FORM_ERROR');
    return [];
  }

  return Object.entries(originalError.errors).map(([key, msg]) => ({
    errors: [msg as string],
    name: toPath(key).map(convertToIndex),
  }));
}

function setFormErrors(error: ApolloError, form: FormInstance) {
  const fieldData = getFormErrorsFromError(error);
  if (fieldData.length) {
    form.setFields(fieldData);
    form.scrollToField(fieldData[0].name);
    // FIXME: Fix errors in test settings form
    // @ts-ignore
    error.fieldData = fieldData;
  }
}

type HandlerOptions = { notification?: string | boolean };

function handleError(error: ApolloError, { notification: msg = true }: HandlerOptions = {}) {
  if (!msg) return;
  const { message, description } = getErrorMessage(error);
  notification.error({
    message: typeof msg === 'string' ? msg : message,
    description,
  });
}

type SuccessHandlerOpts = HandlerOptions & { description?: string };

function handleSuccess(
  data: any,
  { notification: msg = false, description }: SuccessHandlerOpts = {},
) {
  if (msg) {
    notification.success({
      ...(typeof msg === 'string'
        ? { message: msg }
        : {
          message: 'Success',
          description,
        }),
    });
  }
}

export { handleError, handleSuccess, setFormErrors };
