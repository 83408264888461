import React, { ComponentType } from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { flatten, omit, union } from 'lodash';
import { hasPermission } from 'utils';
import { DrawerTypes } from './drawer';

export interface RouteItemCommon {
  name: string;
  icon?: JSX.Element;
  permissions?: string[];
  invisible?: boolean;
  showTitle?: boolean;
}

export interface RouteItemChildWithPath extends RouteItemCommon {
  component: ComponentType<MainContentProps>;
  buttons?: TitleButtonType[];
  exact?: boolean;
  path: string | string[];
}

export interface RouteItemChildWithDrawer extends RouteItemCommon {
  drawer: DrawerTypes;
}

export type RouteItemChild = RouteItemChildWithPath | RouteItemChildWithDrawer;

export interface RouteItemParent extends RouteItemCommon {
  views: RouteItemChild[];
  path?: string | string[];
}

type RouteItemParentWithKey = RouteItemParent & { key: string };

function getFullPath(route: RouteItemChildWithPath, parent: RouteItemParent) {
  const basePath = parent.path || '';
  if (!Array.isArray(route.path)) {
    return `${basePath}${route.path}`;
  }
  return route.path.map((path) => `${basePath}${path}`);
}

export type IterateRoutesProps = {
  routes: RouteItem[];
  default?: JSX.Element;
  render: (props: {
    key: string;
    route: RouteItemChild;
    parent?: RouteItemParentWithKey;
  }) => JSX.Element | null;
  renderParent?: (props: {
    key: string;
    route: RouteItemParent;
    children: JSX.Element[];
  }) => JSX.Element | null;
};

function forceInvisible(route: RouteItem) {
  let permissions: string[] = [];
  if ('views' in route) {
    const withoutPermission = route.views.reduce(
      (prev: RouteItemChild[], child: RouteItemChild) => {
        if (child.invisible) return prev;
        if (child.permissions?.length) {
          permissions = union(permissions, child.permissions);
          return prev;
        }
        return [...prev, child];
      },
      [],
    );
    if (withoutPermission.length) return false;
  }
  else permissions = route.permissions ?? [];
  return !hasPermission(permissions);
}

function IterateRoutes(props: IterateRoutesProps) {
  function recurseFunction(
    routes: RouteItem[],
    parent?: RouteItemParentWithKey,
  ): (JSX.Element | null)[] {
    return flatten(
      routes.map((route, index) => {
        const key = parent ? `${parent.key}.${index}` : `${index}`;

        if (forceInvisible(route)) route = { ...route, invisible: true };

        if ('views' in route) {
          const children = recurseFunction(route.views, { ...route, key }).filter(Boolean);
          if (props.renderParent) {
            return props.renderParent({
              children: children.filter(Boolean) as JSX.Element[],
              route,
              key,
            });
          }
          return children;
        }

        return props.render({
          key,
          parent,
          route: {
            ...route,
            ...('path' in route && {
              path: getFullPath(route, omit(parent, 'key')),
            }),
          },
        });
      }),
    );
  }

  const items = recurseFunction(props.routes).filter(Boolean);
  if (props.default) {
    items.push(props.default);
  }
  return items;
}

export type MapRoutesProps = {
  routes: RouteItem[];
  render: (props: {
    parent?: RouteItemParentWithKey;
    route: RouteItemChildWithPath;
    key: string;
    routeProps: RouteComponentProps;
  }) => JSX.Element;
  default?: (props: RouteComponentProps) => JSX.Element;
};

function MapRoutes(props: MapRoutesProps) {
  const items = IterateRoutes({
    routes: props.routes,
    render: ({ route, key, parent }) =>
      'path' in route ? (
        <Route
          path={route.path}
          key={key}
          exact={route.exact}
          render={(routeProps) => props.render?.({ parent, key, route, routeProps })}
        />
      ) : null,
    default: props.default && <Route path="*" key="-1" render={props.default} />,
  });
  return <Switch>{items}</Switch>;
}

export { getFullPath, IterateRoutes, MapRoutes };
