import { createSlice } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';

function getTokenExpiry(token: string) {
  const decodedData = jwt.decode(token);
  if (!decodedData) throw Error('Unable to Login');
  return (decodedData as jwt.JwtPayload).exp as number;
}

type AuthType = {
  accessToken: string | null;
  refreshToken: string | null;
  tokenExpiry: number | null;
  userId: string | null;
  userName: string | null;
  userRole: string | null;
  permissions: Record<'allow' | 'deny', PlainObject<string[]>> | null;
  instructorId: string | null;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    refreshToken: null,
    tokenExpiry: null,
    userId: null,
    userName: null,
    permissions: null,
    instructorId: null,
  } as AuthType,
  reducers: {
    login(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.tokenExpiry = getTokenExpiry(action.payload.accessToken);
      state.userId = action.payload.userId;
      state.userName = action.payload.userName;
      state.userRole = action.payload.userRole;
      state.permissions = action.payload.permissions;
      state.instructorId = action.payload.instructorId;
    },
    logout(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenExpiry = null;
      state.userId = null;
      state.userRole = null;
      state.permissions = null;
      state.instructorId = null;
    },
    updateAccessToken(state, action) {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.tokenExpiry = getTokenExpiry(action.payload.accessToken);
    },
  },
});

export const { login, logout, updateAccessToken } = authSlice.actions;
export default authSlice.reducer;
