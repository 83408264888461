import React, { Suspense, useCallback } from 'react';
import { startCase } from 'lodash';
import { Layout, Typography, Drawer, Space, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { ErrorPage, Loading } from 'components';
import { useRightDrawer, drawerMap, MapRoutes, MapRoutesProps } from 'router';
import { hasPermission } from 'utils';
import Sidebar from './Sidebar';
import './styles.less';
import Navbar from './Navbar';

type HeaderProps = {
  title: string | JSX.Element;
  buttons?: TitleButtonType[];
  btnProps: TitleButtonProps;
  id?: string;
};

function Header({ title, buttons, btnProps, id }: HeaderProps) {
  return (
    <>
      <div className="header-left">
        <Typography.Title className="title" level={3}>
          {title}
        </Typography.Title>
        {id ? <Tag color="blue">{id}</Tag> : null}
      </div>

      <Space className="title-btns" size="middle">
        {buttons?.map((ButtonComponent, index) => (
          <ButtonComponent key={`${index}`} {...btnProps} />
          // NOTE: Empty string to keep title-btns always in DOM (for Portals)
        )) ?? ''}
      </Space>
    </>
  );
}

function Main(prop: { routes: RouteItem[]; showSidebar?: boolean }) {
  const useDrawerData: UseDrawerReturnType = useRightDrawer();
  const userRole = useSelector((state: RootState) => state.auth.userRole);
  const { routes, showSidebar = true } = prop;

  const getRouteComponent = useCallback<MapRoutesProps['render']>(
    (props) => {
      const { route, routeProps } = props;
      const { component: Component } = route;

      return (
        <>
          {route.showTitle === false ? null : (
            <Layout.Header className="title-bar">
              <Header
                title={route.name}
                buttons={route.buttons}
                btnProps={{ ...routeProps, ...useDrawerData }}
              />
            </Layout.Header>
          )}
          <Layout.Content
            style={
              route.name === 'Live Class'
                ? {
                  margin: '0px',
                  paddingBottom: '0px',
                  width: '100vw',
                  height: '100vh',
                  display: ' flex',
                  flexFlow: 'column',
                }
                : {}
            }
          >
            <Component route={route} {...routeProps} {...useDrawerData} />
          </Layout.Content>
        </>
      );
    },
    [useDrawerData],
  );

  return (
    <>
      <Layout className="root-layout">
        {showSidebar ? (
          <Layout.Sider collapsible className="sider">
            <Sidebar routes={routes} drawerData={useDrawerData} />
          </Layout.Sider>
        ) : null}
        <Layout className="main" id="main-layout">
          <Layout.Header className="navbar">
            <Navbar showLogo={!showSidebar} />
          </Layout.Header>
          <Suspense fallback={<Loading size="large" />}>
            <MapRoutes
              routes={routes}
              render={getRouteComponent}
              default={() => <ErrorPage.NotFound />}
            />
          </Suspense>
        </Layout>
      </Layout>

      {useDrawerData.drawers.map((drawer, index) => {
        const drawerChild = drawerMap[drawer.type];
        const { component: DrawerChildComponent } = drawerChild;
        const title =
          drawerChild.title || `${drawer.id ? 'Edit' : 'Add'} ${startCase(drawer.type)}`;

        return (
          <Drawer
            key={`${drawer.type}_${drawer.id}_${index}`}
            title={
              <Header
                title={title}
                buttons={drawerChild.buttons}
                btnProps={useDrawerData}
                id={drawerChild.title ? undefined : drawer.id}
              />
            }
            closable={false}
            push={false}
            visible={drawer.visible}
            width={userRole === 'TELECALLER' ? '100vw' : 1000 - 24 * index}
            onClose={() => useDrawerData.closeDrawer()}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {drawer.visible ? (
              hasPermission(drawerChild.permissions) ? (
                <Suspense fallback={<Loading size="large" />}>
                  <DrawerChildComponent
                    id={drawer.id}
                    {...useDrawerData}
                    initialData={drawer.data}
                  />
                </Suspense>
              ) : (
                <ErrorPage.PermissionError />
              )
            ) : null}
          </Drawer>
        );
      })}
    </>
  );
}

export default Main;
