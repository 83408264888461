import { FrownOutlined } from '@ant-design/icons';
import './styles.scoped.less';
import React from 'react';

interface ErroPageProps {
  status?: any;
  title: string;
  description?: string;
  page?: boolean;
}

function ErrorPage(props: ErroPageProps) {
  const { status = <FrownOutlined />, title, description, page = true } = props;

  return (
    <div className={page ? 'error-page' : 'error-component'}>
      <h1 className="title">{status}</h1>
      <h2 className="sub-title">{title}</h2>
      {description ? <h4 className="description">{description}</h4> : null}
    </div>
  );
}

ErrorPage.NotFound = () => (
  <ErrorPage status={404} title="Page not found :(" description="Oops! Looks like you got lost." />
);

ErrorPage.ServerError = () => (
  <ErrorPage
    status={500}
    title="Internal Server Error :/"
    description="Oops! Looks like we made a mistake."
  />
);

ErrorPage.PermissionError = () => (
  <ErrorPage
    status={<FrownOutlined />}
    title="Permission Denied"
    description="You don't have permission to access this page. Please contact admin."
  />
);

export default ErrorPage;
