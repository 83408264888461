import { gql } from '@apollo/client';
import { omit } from 'lodash';
import { IMAGE_FIELDS, COMMON_LIST_ARGS, toGqlArgs, toGqlVariables } from '../utils';

const LIST_ARGS = omit(COMMON_LIST_ARGS, 'phrase');

const commonFields = `
  id
  image {
    ${IMAGE_FIELDS}
  }
  status
  target
  appScreen
  itemId
  link
  redirectType
  apps
`;

const GET = gql`
  query GetBanner($id: ID!) {
    banner(id: $id) {
      ${commonFields}
      imageId
      examCategoryId
      examIds
      year
      targetCategoryId
    }
  }
`;

const LIST = gql`
  query ListBanners${toGqlVariables(LIST_ARGS)} {
    items: banners${toGqlArgs(LIST_ARGS)} {
      nodes {
        ${commonFields}
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;

const CREATE = gql`
  mutation CreateBanner($input: CreateBannerInput!) {
    createBanner(input: $input) {
      ${commonFields}
    }
  }
`;

const UPDATE = gql`
  mutation UpdateBanner($input: UpdateBannerInput!) {
    updateBanner(input: $input) {
      ${commonFields}
    }
  }
`;

export { GET, LIST, CREATE, UPDATE };
