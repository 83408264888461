import { Ref, useCallback } from 'react';
import { reverse, sortBy, trim } from 'lodash';
import { Language } from 'constants/Enums';
import PropTypePresets from './propTypePresets';
import * as Formatter from './formatter';
import logger from './logger';
import hasPermission from './permission';

function MergeRefs<T>(refs: (Ref<T> | null)[]): Ref<T> {
  return useCallback(
    (inst) => {
      const filteredRefs = refs.filter(Boolean);
      if (!filteredRefs.length) return;

      filteredRefs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(inst);
        }
        else if (ref) {
          // @ts-ignore
          ref.current = inst;
        }
      });
    },
    [refs],
  );
}

function getSlugFromHtml(html: string, maxLength = 50) {
  if (!html) return '';
  // remove katex
  html = html.replace(/<span class="math-tex">(.*?)<\/span>/g, (m, $1) => $1.replace(/\\\w+/g, ''));
  const span = document.createElement('span');
  span.innerHTML = html;
  const text = span.textContent || span.innerText;
  return trim(
    text
      .replace(/[\W_]+/g, '-')
      .slice(0, maxLength)
      .toLowerCase(),
    '-',
  );
}

function ifLangExistsInQuestion(question: any, lang: Language) {
  if (
    question.text[lang] ||
    question.solution.text[lang] ||
    question.hint?.[lang] ||
    question.options?.some((op: any) => op[lang]) ||
    question.otherSolutions?.some((sol: any) => sol.text[lang])
  )
    return true;
  return false;
}

function sortDesc<T>(arr: T[]): T[] {
  return reverse(sortBy(arr));
}

function getRelativeDimension(val: number) {
  return val / 1092;
}

export {
  PropTypePresets,
  Formatter,
  logger,
  MergeRefs,
  getSlugFromHtml,
  sortDesc,
  getRelativeDimension,
  ifLangExistsInQuestion,
  hasPermission,
};
